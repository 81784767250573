<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default" @click="main()">
            <i class="fa fa-table mr-2" aria-hidden="true"></i>
            {{ $t("scheduleDetail.title") }}
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover-default"
            v-if="$route.name === 'roster.add.employee'"
            >/
            <i
              class="fal fa-users"
              style="margin: 0 10px 0 0; font-size: 20px"
            ></i>
            Add Employee
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'scheduleAttractEmployee.create'"
          >
            <span v-show="true">/</span>
            <span>{{$t('action.create')}}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>

    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "employee.roster.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
</style>
